/* eslint-disable react/prefer-stateless-function */
import React from "react" // import the component part of React
import Seo from "../components/seo"
import { Container, Row, Col, ListGroup } from "react-bootstrap"
// Components and Widgets
import FadeIn from "../components/fade-in"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import AboutAndQuote from "../components/aboutandquote"

const Features = () => {
  return (
    <Layout>
      <Seo
        title="Features - Signaclass"
        description="See our complete list of available features here. Signaclass is a class management system that helps activity businesses increase their revenue."
      />

      <div>
        <FadeIn delay={200} duration={1500}>
          <div className="shaded pt-5 pb-5" id="header">
            <Container id="header">
              <Row className="mt-5 mb-5 align-items-center">
                <Col lg="6" className="order-lg-1">
                  <h1>
                    <strong>Powerful features for your business.</strong>
                  </h1>
                  <p className="flow-text mt-5">
                    From automatic covering payments, through to class register
                    tracking, we&apos;ve worked with businesses like yours to
                    develop the right features for success.
                  </p>
                </Col>
                <Col lg="6" className="mt-5 mb-5 order-lg-0">
                  <StaticImage
                    src="../images/Analytics.svg"
                    placeholder="blurred"
                  />
                </Col>
              </Row>
            </Container>
          </div>

          <div id="features" className="pt-5 pb-5">
            <Container>
              <Row className="mt-5 mb-5 align-items-center">
                <Col lg="12">
                <h2 className="text-center display-3">
                    <strong>Feature List.</strong>
                  </h2>
                </Col>
              </Row>
              <Row className="mt-5 mb-5 align-items-center">
                <Col lg="6">
                  <ListGroup as="ul">
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Public Class Schedule</div>
                        Get a public class schedule which lists all your
                        sessions and allows customers to easily filter by day,
                        type, tutor and location, all in a simple format.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">
                          Subscription Payments with Stripe
                        </div>
                        Customers can subscribe to your weekly classes and pay
                        monthly to your Stripe account.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">
                          Block Payments with Stripe
                        </div>
                        Block classes can be paid in full at the start of
                        variable dates.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Tutor Accounts</div>
                        Create sub-accounts for staff so that tutors can see
                        their schedules easily and manage registers.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">
                          Automatic Covering Payments
                        </div>
                        Set your classes to automatically calculate how many
                        lessons have been missed this month, or in total from a
                        block, so that your customers always pay the right
                        amount.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Hidden Classes</div>
                        Hide classes from the public schedule to send the link
                        to selected people or phase them out over time.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Regular Bookings</div>
                        Run your classes at a set time, on a set day of the week
                        and take a monthly payment by card subscription.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Block Bookings</div>
                        Run classes with irregular times and dates or a single
                        date, take a complete payment at the start.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Transfer Participants</div>
                        Move participants between your regular bookings with
                        ease to match progress or meet the scheduling needs of
                        customers.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Email Notifications</div>
                        Get notifications by email whenever a participant joins
                        your classes or leaves; the customers get them too.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Progress Reports</div>
                        Setup grading criteria against your classes and notify
                        participants of their performance.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Awards</div>
                        Setup awards with purchase links and encourage your
                        participants to get certificates and trophies that make
                        them feel rewarded.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Automatic Signup Reminder</div>
                        Sometimes customers forget to complete their signup;
                        they get distracted or run out of time. Signaclass
                        automatically reminds customers to finish if they
                        consented to marketing.
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col lg="6">
                  <ListGroup as="ul">
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Register Management</div>
                        Manage registers for both regular and block bookings
                        through the admin dashboard or setup tutor accounts so
                        staff can enter attendance.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Waiting Lists</div>
                        Maintain waiting lists for your regular classes so that
                        you never miss a booking slot and your classes are
                        always full.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Customer Email Consent</div>
                        Gather customer consent for marketing emails during
                        their signup process.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Export Mailing Lists</div>
                        Export mailing lists to enter into your chosen email
                        marketing platform.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Your Color &amp; Logo</div>
                        Choose your brand color and logo for the app, so that
                        customers know they can trust the portal and your brand
                        image is maintained.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">
                          Manage Terms &amp; Conditions
                        </div>
                        Always stay compliant and protect yourself from
                        confusions and disagreements by entering your privacy
                        policy and terms and conditions into Signaclass.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Tax Support</div>
                        Improve your tax compliance by entering a Stripe tax
                        code that will show on documents generated for your
                        customers by Signaclass.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Bring Your Own Analytics</div>
                        Signaclass allows you to manage your analytics with
                        Google Tag Manager and transmits key events you might
                        need to understand customer behaviour, whilst allowing
                        customers to accept or reject this.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Multiple Locations</div>
                        Signaclass was made with businesses that operate from
                        multiple locations in mind and we don&apos;t limit our
                        plans by the amount of venues.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Customer Dashboard</div>
                        Signaclass lets customers easily see their bookings,
                        participants, awards, progress and billing information,
                        and they can change their details and request
                        cancellation, saving you administration time.
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Fully Mobile</div>
                        Signaclass is designed to be as accessible on mobile
                        phones as it is on desktops, and accessible over the
                        internet, so that you can manage attendance and check
                        details directly from your classes.
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Container>
          </div>
          <AboutAndQuote />
        </FadeIn>
      </div>
    </Layout>
  )
}

export default Features
